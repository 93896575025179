import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';

import Blockquote from '../components/Blockquote';
import ButtonAsAnchor from '../components/Button/ButtonAsAnchor';

import { AboutQuery } from '../../graphql-types';
import Checklists from '../components/Checklists';
import SEO from '../containers/SEO/SEO';

import './o-about-section.scss';
import './o-suggested-checklists.scss';

interface Props {
  data: AboutQuery;
}

const About: FC<Props> = ({ data }) => (
  <Layout>
    <SEO title="About" />

    <div className="row o-about-section">
      <section className="col col--md-10 col--lg-8">
        <div className="u-text-align-center u-padding-bottom-xlarge">
          <svg
            className="u-img-fluid"
            width="710"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 676 430"
          >
            <title>What is Checklist?</title>
            <g fill="none" fillRule="evenodd">
              <path
                fill="#232939"
                d="M193.645 100.683a5.116 5.116 0 003.646 1.52c1.113 0 2.24-.357 3.18-1.097a203.888 203.888 0 0141.256-25.037v188.615c0 .477.054.938.167 1.368.57 34.77 22.589 65.987 55.148 78.001.576.21 1.178.315 1.784.315a5.102 5.102 0 002.944-.933 5.173 5.173 0 002.215-4.237v-69.832c0-.288-.032-.583-.077-.866a37.17 37.17 0 01-.263-1.969c.218-.575.34-1.206.34-1.847V5.559c0-1.462-.621-2.86-1.71-3.848a5.149 5.149 0 00-3.45-1.32c-.183 0-.364 0-.543.024-50.916 5.367-98.853 25.461-138.63 58.095a5.16 5.16 0 00-1.876 3.746 5.109 5.109 0 001.504 3.904l34.365 34.523zm346.763 318.973C572.213 376 591 322.263 591 264.214c0-60.783-21.116-120.126-59.49-167.12a5.21 5.21 0 00-3.75-1.906h-.264a5.17 5.17 0 00-3.66 1.52l-34.495 34.546a5.15 5.15 0 00-.424 6.817c28.313 36.392 43.284 80.004 43.284 126.143 0 61.956-27.523 117.597-70.971 155.303l79.178.139zm-347.936-.402c-43.275-37.702-70.673-93.228-70.673-155.04 0-46.14 14.97-89.764 43.277-126.143a5.157 5.157 0 00-.424-6.824L130.158 96.7a5.161 5.161 0 00-3.659-1.512h-.265a5.184 5.184 0 00-3.748 1.899C84.126 144.087 63 203.444 63 264.214c0 58.375 18.999 112.392 51.13 156.178l78.342-1.138zm218.488.333c55.54-29.873 93.394-88.645 93.394-156.116 0-66.298-36.184-126.084-94.658-156.804V75.186a204.723 204.723 0 0143.837 26.145 5.191 5.191 0 003.195 1.103c1.328 0 2.65-.522 3.654-1.527l34.435-34.506a5.152 5.152 0 001.495-3.904 5.17 5.17 0 00-1.88-3.746C453.88 25.547 404.952 5.367 352.948.417a5.155 5.155 0 00-3.962 1.315 5.146 5.146 0 00-1.694 3.83V141.63a5.169 5.169 0 004.144 5.067c55.12 11.198 95.133 60.31 95.133 116.773 0 65.694-53.329 119.156-118.889 119.156s-118.889-53.462-118.889-119.156c0-23.035 6.589-45.402 19.076-64.688a5.138 5.138 0 00-.693-6.446l-34.093-34.173a5.159 5.159 0 00-3.665-1.525 3.82 3.82 0 00-.382.017 5.19 5.19 0 00-3.785 2.091C162.839 189.29 151 225.51 151 263.471c0 67.48 37.863 126.259 93.418 156.128l166.542-.012z"
              />
              <path
                fill="#181C27"
                d="M187.645 100.683a5.116 5.116 0 003.646 1.52c1.113 0 2.24-.357 3.18-1.097a203.888 203.888 0 0141.256-25.037v188.615c0 .477.054.938.167 1.368.57 34.77 22.589 65.987 55.148 78.001.576.21 1.178.315 1.784.315a5.102 5.102 0 002.944-.933 5.173 5.173 0 002.215-4.237v-69.832c0-.288-.032-.583-.077-.866a37.17 37.17 0 01-.263-1.969c.218-.575.34-1.206.34-1.847V5.559c0-1.462-.621-2.86-1.71-3.848a5.149 5.149 0 00-3.45-1.32c-.183 0-.364 0-.543.024-50.916 5.367-98.853 25.461-138.63 58.095a5.16 5.16 0 00-1.876 3.746 5.109 5.109 0 001.504 3.904l34.365 34.523zm346.763 318.973C566.213 376 585 322.263 585 264.214c0-60.783-21.116-120.126-59.49-167.12a5.21 5.21 0 00-3.75-1.906h-.264a5.17 5.17 0 00-3.66 1.52l-34.495 34.546a5.15 5.15 0 00-.424 6.817c28.313 36.392 43.284 80.004 43.284 126.143 0 61.956-27.523 117.597-70.971 155.303l79.178.139zm-347.936-.402c-43.275-37.702-70.673-93.228-70.673-155.04 0-46.14 14.97-89.764 43.277-126.143a5.157 5.157 0 00-.424-6.824L124.158 96.7a5.161 5.161 0 00-3.659-1.512h-.265a5.184 5.184 0 00-3.748 1.899C78.126 144.087 57 203.444 57 264.214c0 58.375 18.999 112.392 51.13 156.178l78.342-1.138zm218.488.333c55.54-29.873 93.394-88.645 93.394-156.116 0-66.298-36.184-126.084-94.658-156.804V75.186a204.723 204.723 0 0143.837 26.145 5.191 5.191 0 003.195 1.103c1.328 0 2.65-.522 3.654-1.527l34.435-34.506a5.152 5.152 0 001.495-3.904 5.17 5.17 0 00-1.88-3.746C447.88 25.547 398.952 5.367 346.948.417a5.155 5.155 0 00-3.962 1.315 5.146 5.146 0 00-1.694 3.83V141.63a5.169 5.169 0 004.144 5.067c55.12 11.198 95.133 60.31 95.133 116.773 0 65.694-53.329 119.156-118.889 119.156s-118.889-53.462-118.889-119.156c0-23.035 6.589-45.402 19.076-64.688a5.138 5.138 0 00-.693-6.446l-34.093-34.173a5.159 5.159 0 00-3.665-1.525 3.82 3.82 0 00-.382.017 5.19 5.19 0 00-3.785 2.091C156.839 189.29 145 225.51 145 263.471c0 67.48 37.863 126.259 93.418 156.128l166.542-.012z"
              />
              <g fill="#92AEE7">
                <path
                  fillRule="nonzero"
                  d="M624.906 98.398c-.938-4.76-2.417-8.126-4.438-10.099-3.032-2.96-1.087-4.22 1.436-3.896 2.524.324 7.068 2.058 9.786-4.664 2.717-6.722 6.791-5.008 8.898-2.337 2.107 2.671-.33 4.902.531 6.068.86 1.167-1.07 11.942-9.695 15.598l-6.518-.67z"
                />
                <path d="M572.543 164.363s25.732-9.581 40.168-21.744c18.931-15.947 21.137-45.026 21.137-45.026s-2.792-6.443-9.259 1.106c-7.26 10.048-15.978 20.905-28.734 30.489-7.958 5.98-34.818 11.585-34.818 11.585l11.506 23.59z" />
                <path
                  fillRule="nonzero"
                  d="M572.718 164.286c-6.453 3.147-14.671.153-17.819-6.3-3.147-6.453-1.507-14.846 6.138-17.213 7.645-2.366 14.954-.012 18.101 6.441 3.148 6.453.033 13.925-6.42 17.072z"
                />
              </g>
              <path
                fill="#181C27"
                fillRule="nonzero"
                d="M4.027 416.244l34.346-.024 60.141-.046 173.007-.148 19.703-10.148 11.183 5.252 12.914-7.883 13.569 2.7 14.876-2.7 13.5 11.807 315.51 2.355a3.144 3.144 0 01.964 6.13l-17.499 5.785a4 4 0 01-1.256.202H24.625a4 4 0 01-1.204-.186l-20.416-6.448a3.403 3.403 0 011.022-6.648z"
              />
              <path
                fill="#92AEE7"
                d="M506.98 374.715l-8.815 12.085-9.413 16.304 31.383 18.12a2.172 2.172 0 002.76-3.26l-15.286-20.073 8.344-18.275-8.973-4.901z"
              />
              <path
                fill="#344976"
                d="M584.967 224.652s-3.524 73.959-15.808 94.53c-11.437 19.153-57.135 75.037-57.135 75.037l-14.5-6.477s45.091-83.496 47.555-90.325c2.463-6.83-28.89-66.943-28.89-66.943s23.62-8.49 35.372-25.042l33.406 19.22z"
              />
              <path
                fill="#92AEE7"
                d="M612.379 386.742l-1.591 12.463v21.237h36.238a2.172 2.172 0 00.76-4.202l-23.274-9.74-1.912-20-10.221.242z"
              />
              <path
                fill="#2A3C65"
                fillRule="nonzero"
                d="M543.365 288.447l-5.623-13.693 37.23 29.448c-15.467-4.181-26.003-9.433-31.607-15.755z"
              />
              <path
                fill="#4E679C"
                d="M560.502 231.423s41.792 45.413 51.151 67.487c10.471 24.695 14.04 98.514 14.04 98.514l-15.01 2.766s-20.423-80.343-27.288-89.713c-4.293-5.861-69.516-38.095-67.206-81.102l44.313 2.048z"
              />
              <path
                fill="#344976"
                d="M584.09 161.47c7.356-1.705 20.005-8.897 20.005-8.897l-7.907-24.181-25.015 8.267C560.39 139.552 555 144.977 555 152.935c0 11.937 21.734 10.24 29.09 8.535z"
              />
              <path
                fill="#FE2D5B"
                fillRule="nonzero"
                d="M494.678 133.352l33.652 27.175c7.734 6.246 8.94 17.578 2.695 25.313-.126.155-.254.31-.385.46L345.562 400.45c-7.102 7.999-16.778 8.48-26.751 9.204-8.903.646-18.042 2.298-25.798-3.215l-.496-.36-87.92-59.488c-8.006-5.418-10.304-16.193-5.203-24.405l23.033-37.084c5.245-8.445 16.343-11.039 24.788-5.794a18 18 0 01.409.262l38.213 25.19c13.986 9.219 32.643 6.614 43.568-6.083l140.32-163.062c6.342-7.369 17.39-8.37 24.953-2.263z"
              />
              <path
                fill="#E6004D"
                fillRule="nonzero"
                d="M480.684 226.475c2.605 4.31 5.187 4.697 7.746 1.16 3.838-5.303 6.282-5.303 7.524-5.303 1.24 0 2.568-3.932 0-5.266s-4.366-7.354-1.563-11.254c2.804-3.9 19.105-25.756 23.125-28.486 2.68-1.82 7.555-3.563 14.627-5.23l-1.192-14.058-24.836 15.461-20.075 35.496-5.356 17.48z"
              />
              <path
                fill="#C30041"
                fillRule="nonzero"
                d="M229.453 279.305c.462-.456 1.87-1.249 4.224-2.378 2.354-1.13 6.3-2.204 11.837-3.225l63.579 33.055L469.765 135.17c6.097-6.51 16.042-7.575 23.377-2.501l2.053 1.419c-2.475-.453-5.139-.013-7.992 1.32-2.854 1.334-5.698 3.865-8.531 7.593L334.375 307.56c-14.346 16.36-38.764 19.169-56.449 6.492l-48.473-34.747z"
              />
              <path
                fill="#5D7AB6"
                d="M570.3 134.426s15.71 5.267 17.433 28.593c1.871 10.821-3.226 71.227-3.228 71.18-33.356 5.76-68.316-4.824-68.316-4.824s5.125-46.973 10.24-60.996c6.353-17.41 8.823-28.26 15.631-31.935 5.887-3.177 13.275-2.018 13.275-2.018H570.3z"
              />
              <path
                fill="#AD003A"
                fillRule="nonzero"
                d="M218.74 291.005c7.88-7.533 15.505-8.564 22.876-3.093 7.527 5.587 8.325 5.936 14.432 10.424 8.424 6.192 15.677 11.045 22.575 15.604 13.04 8.616 30.237 5.106 51.591-10.53l-79.706-33.618c2.812 2.121-.56 3.7-10.119 4.738-9.557 1.038-16.774 6.53-21.648 16.475z"
              />
              <path
                fill="#C30041"
                fillRule="nonzero"
                d="M291.693 405.686l10.714 5.444 12.914-7.883 13.569 2.7 14.086-2.7 38.655-45.139-43.378 41.988h-22.932l-12.914 7.697z"
              />
              <path
                fill="#92AEE7"
                d="M544.674 125.653c3.277 3.6 12.947 4.23 17.062.659 1.186-1.03 8.586-3.922 9.763-7.499 1.855-5.636-2.856-12.663-3.916-14.42-6.617-10.978-22.13-6.996-22.13-6.996s-2.604 7.84-3.25 15.557c-.081.976-.131 1.95-.141 2.905-.04 3.953.613 7.597 2.612 9.794z"
              />
              <path
                fill="#92AEE7"
                d="M567.332 118.693l3.212 15.733s-10.743 10.765-16.376.572l-.174-11.061 13.338-5.244z"
              />
              <path
                fill="#5D7AB6"
                fillRule="nonzero"
                d="M553.994 128.693c2.17-.506 4.928-2.191 8.275-5.055-1.65 4.821-4.365 7.795-8.147 8.92l-.128-3.865z"
              />
              <path
                fill="#4E679C"
                fillRule="nonzero"
                d="M544.831 99.3l-.575 2.298c2.52.295 4.841-.145 6.963-1.321 3.183-1.765 7.987 3.254 8.322 5.043.335 1.79 2.065 6.73 3.197 4.9.394-.64.985-2.037 1.783-2.455.485-.255 1.383-.255 2.692 0L553.791 95.2l-8.96 4.1z"
              />
              <path
                fill="#5D7AB6"
                d="M583.663 122.384c2.071-3.542 3.209-10.585 1.812-17.235-1.015-4.828-7.798-7.754-10.455-9.897-.473-.381-.99-.71-1.409-1.15-.94-.992-1.267-2.407-1.81-3.668-.447-1.032-1.098-2.028-2.074-2.558-2.242-1.215-5.225.433-7.466-.784-.484-.265-.895-.646-1.333-.984-3.212-2.476-6.857-1.898-10.36-.61-1.638.604-2.177 1.262-3.54 2.344-1.49 1.18-3.552 1.102-5.05 2.258-1.572 1.214-1.968 3.441-1.984 5.452-.007.729.026 1.48.33 2.14.387.848 1.178 1.44 2.015 1.829 2.007.931 4.264.572 6.393.41 2.08-.162 4.497-1.654 6.53-.879 1.985.754 8.242 7.083 8.863 7.98 1.234 1.785 6.419-1.883 2.929 8.205-.547 1.582-3.014 1.937-2.284 3.654.481 1.13.137 11.106 5.224 12.162 5.086 1.055 13.302-8.041 13.67-8.669z"
              />
              <path
                fill="#232939"
                fillRule="nonzero"
                d="M270.74 416.386l20.124-5.252 11.798 4.425 16.749-4.425 15.566 6.417h20.569l-12.57-14.304-14.086 2.7-13.569-2.7-12.914 7.883-10.714-5.444z"
              />
              <path
                fill="#C30041"
                fillRule="nonzero"
                d="M384.509 135.692h11.939l14.513 18.261 28.896-1.79 16.629 4.383-45.525 5.4-26.452-16.972z"
              />
              <path
                fill="#FE2D5B"
                fillRule="nonzero"
                d="M493.866 11.255l37.862 32.34c7.559 6.457 8.452 17.819 1.996 25.378l-.13.15-199.47 228.406c-13.387 15.385-36.238 18.065-52.807 6.289l-.5-.362-101.603-71.444c-7.716-5.425-9.935-15.886-5.087-23.976l24.819-41.416a18 18 0 0116.931-8.686l10.037.834 62 37.456A12 12 0 00303.022 194l60.24-66.651L468.942 12.74c6.559-7.113 17.567-7.769 24.924-1.485z"
              />
              <path
                fill="#E6004D"
                fillRule="nonzero"
                d="M199.115 166.163c2.719-3.247 5.262-5.43 7.63-6.552 1.632-.773 5.073-1.936 10.321-3.49a18 18 0 0115.113 2.296l55.596 37.168a12 12 0 0015.564-1.92l38.338-42.332 18.43-2.348-54.21 63.335a12 12 0 01-16.087 1.965l-68.051-48.566a18.977 18.977 0 00-22.644.444z"
              />
              <path
                fill="#C30041"
                fillRule="nonzero"
                d="M295.749 197.604l1.872 13.954a4 4 0 006.904 2.181L482.056 21.39c2.7-3.157 5.668-5.157 8.903-6.002 3.236-.845 6.422-.351 9.56 1.481l-6.613-5.625c-7.377-6.275-18.396-5.589-24.937 1.553L302.223 194.849c-1.584.985-2.74 1.632-3.47 1.943-.73.31-1.731.58-3.004.812z"
              />
              <path
                fill="#92AEE7"
                d="M132.816 382.067l1.59 12.463v21.237H98.17a2.172 2.172 0 01-.76-4.202l23.273-9.74 1.913-20 10.221.242z"
              />
              <path
                fill="#344976"
                d="M121.12 217.271s-19.439 64.47-15.745 89.188c3.693 24.718 12.4 88.248 12.4 88.248l16.65-1.162s1.344-78.185-3.246-91.755c-4.59-13.57 57.112-81.123 57.112-81.123s-30.302-3.647-34.822-16.5l-32.348 13.104z"
              />
              <path
                fill="#92AEE7"
                d="M73.911 393.34l-7.397 23.085-34.655-10.595a2.172 2.172 0 01.502-4.24l25.105-2.512 7.675-18.565 8.77 12.828z"
              />
              <path
                fill="#4E679C"
                d="M145.477 221.532s-49.981 45.787-58.954 68.02C76.485 314.428 58.966 391.17 58.966 391.17l15.055 2.504s24.781-49.306 41.958-93.05c1.969-5.013 75.163-37.907 72.102-80.867l-42.604 1.775z"
              />
              <path
                fill="#5D7AB6"
                d="M141.796 112.759c-2.133-3.505-3.393-10.527-2.112-17.2.93-4.846 7.662-7.89 10.28-10.079.466-.39.978-.727 1.389-1.174.923-1.009 1.225-2.43 1.746-3.699.429-1.04 1.062-2.047 2.028-2.594 2.222-1.254 5.232.342 7.452-.914.48-.274.883-.662 1.316-1.007 3.168-2.532 6.822-2.018 10.347-.792 1.649.576 2.199 1.224 3.58 2.283 1.51 1.154 3.572 1.04 5.09 2.17 1.592 1.186 2.027 3.406 2.078 5.416.02.729 0 1.48-.292 2.146-.372.854-1.153 1.46-1.983 1.863-1.99.966-4.254.647-6.385.52-2.082-.124-4.526-1.574-6.545-.763-1.97.788-8.116 7.225-8.722 8.134-1.203 1.806-8.14-1.338-4.021 8.093.67 1.534 3.64 14.882-1.427 16.026-5.068 1.144-13.441-7.808-13.82-8.43z"
              />
              <path
                fill="#92AEE7"
                d="M153.507 109.435c2.858 6.943 3.837 12.007 2.936 15.193-.27.955-.892 2.236-1.866 3.845 0 0 8.884 10.682 17.517 2.356l.542-14.182-19.13-7.212z"
              />
              <path
                fill="#92AEE7"
                d="M153.071 118.931c3.112 3.542 19.604 2.663 23.712-.641 1.21-.974 5.046.507 7.606-2.831 3.887-5.069-5.449-13.28-6.427-14.968-6.194-10.7-21.207-8.983-21.207-8.983s-9.67 20.609-3.684 27.423z"
              />
              <path
                fill="#344976"
                d="M153.387 129.488l-17.716 31.81c-5.57-3.672-9.51-6.065-11.82-7.177-2.08-1.001-4.57-1.743-7.467-2.227 6.03-7.284 11.325-12.684 15.882-16.2 6.265-4.836 13.306-6.904 21.121-6.206z"
              />
              <path
                fill="#5D7AB6"
                d="M182.756 134.36s10.973 5.274 9.64 36.956c-.454 10.807-4.317 48.486-4.315 48.44-43.983 17.326-66.96-2.485-66.96-2.485s4.136-33.365 7.362-46.82c.07-.29 2.028-13.868 7.807-23.619 8.328-14.052 18.287-18.359 18.287-18.359 1.798 1.554 3.47 2.492 5.015 2.815 3.322.694 10.412-.896 12.502-.46 2.714.568 6.268 1.745 10.662 3.533z"
              />
              <path
                fill="#92AEE7"
                d="M110.646 172.266c1.792 23.767 45.564 15.587 45.564 15.587s8.385 2.468 6.147-6.827c-1.1-3.061-20.827-8.76-33.874-10.575.902-4.693 1.84-8.651 2.815-11.873.22-.726-3.189-2.666-6.524-4.259-1.334-.637-3.453-1.336-6.358-2.097-3.121 4.904-5.27 9.085-6.447 12.543-1.177 3.46-1.618 5.96-1.323 7.501z"
              />
              <path
                fill="#5D7AB6"
                fillRule="nonzero"
                d="M113.85 181.13c1.358-3.673 5.195-4.992 11.512-3.955 1.865.306 4.492 1.95 7.327 2.172 8.025.63 18.009-1.85 29.513 1.307l-4.296 6.483-44.055-6.006z"
              />
              <path
                fill="#C30041"
                fillRule="nonzero"
                d="M191.793 178.347l-4.026 41.646c.624 4.42-.066 9.902-2.07 16.448 6.665 0 10.327-11.96 10.986-35.882.715-25.963 4.32-37.04 2.432-34.396a88.403 88.403 0 00-7.322 12.184z"
              />
              <g fill="#92AEE7">
                <path
                  fillRule="nonzero"
                  d="M488.75 207.559c1.35 4.659 3.117 7.884 5.302 9.673 3.278 2.685 1.45 4.11-1.091 4.007-2.543-.103-7.221-1.434-9.342 5.499-2.121 6.933-6.33 5.58-8.661 3.104-2.332-2.478-.099-4.912-1.058-6-.959-1.087.026-11.99 8.299-16.383l6.551.1z"
                />
                <path d="M535.021 137.775s-24.877 10.782-37.976 24.668c-17.006 18.026-17.133 46.696-17.133 46.696s3.343 6.176 9.128-1.908c6.357-10.643 14.095-22.218 25.968-32.877 7.406-6.65 33.675-14.576 33.675-14.576l-13.662-22.003z" />
              </g>
              <path
                fill="#4E679C"
                fillRule="nonzero"
                d="M168.956 225.402c8.366-7.304 13.946-15.401 16.74-24.29 2.795-8.89 4.797-24.914 6.006-48.072 1.88 13.257.568 35.574-3.935 66.953-4.388 1.623-7.658 2.744-9.81 3.363-2.028.583-5.028 1.265-9 2.046z"
              />
              <path
                fill="#5D7AB6"
                fillRule="nonzero"
                d="M156.742 120.633c2.187.384 6.048.116 11.582-.805-2.462 3.159-6.371 4.548-11.727 4.169.053-.606.1-1.186.145-1.742.022-.285.022-.825 0-1.622z"
              />
              <path
                fill="#4E679C"
                fillRule="nonzero"
                d="M155.972 92.942c3.073-.734 5.135-.847 6.187-.339 1.578.763 3.645 3.491 6.633 3.491 2.987 0 5.178 6.81 4.366 7.978-.542.78-.542 2.052 0 3.816.68-1.797 1.261-2.872 1.743-3.225 1.108-.81 2.075-.59 2.908-.59 1.378 0-.703-5.791-6.243-17.373l-8.668 4.118c-1.435-.149-2.517-.209-3.248-.18-.73.029-1.704.17-2.922.424l-.756 1.88z"
              />
              <path
                fill="#5D7AB6"
                d="M188.442 125.526c3.29-2.45 7.09-9.49 8.401-16.157.953-4.841-4.148-10.184-5.756-13.195-.286-.536-.634-1.041-.847-1.61-.479-1.28-.226-2.71-.234-4.083-.008-1.125-.218-2.296-.909-3.165-1.59-1.995-4.979-1.643-6.566-3.639-.343-.433-.571-.944-.843-1.426-1.989-3.534-5.57-4.427-9.298-4.61-1.744-.084-2.497.31-4.174.775-1.832.504-3.7-.375-5.531.105-1.922.503-3.156 2.399-3.957 4.243-.29.668-.554 1.372-.533 2.1.025.93.522 1.785 1.14 2.47 1.484 1.641 3.703 4.193 5.726 4.875 1.977.664 4.786.235 6.354 1.743 1.532 1.469 4.82 7.74 5.04 8.81.439 2.124 8.049 1.807.697 9.01-1.196 1.17-8.95 12.433-4.68 15.392 4.27 2.96 15.387-1.204 15.97-1.638z"
              />
              <path
                fill="#2A3C65"
                fillRule="nonzero"
                d="M121.12 217.271c15.483 10.892 37.699 11.8 66.647 2.722.214 1.648.311 2.768.292 3.36-.12 3.704-.568 5.179-.568 5.179s-19.913 4.17-44.491 0c-5.6-.95-12.892-4.704-21.88-11.26zM131.611 296.432c.677-1.863 1.267-3.342 1.768-4.436.501-1.093 1.31-2.664 2.427-4.713-6.15 3.794-10.978 6.844-14.48 9.15-3.504 2.305-5.22 3.562-5.151 3.77L114.579 304l17.032-7.568z"
              />
              <path
                fill="#344976"
                fillRule="nonzero"
                d="M530.334 177.28c18.714-8.24 27.031-18.934 24.952-32.083-10.013 11.357-18.349 18.376-25.008 21.055-1.122.452-2.38.98-3.773 1.586l3.829 9.441z"
              />
              <path
                fill="#4E679C"
                fillRule="nonzero"
                d="M532.143 172.096c-3.163-11.79-9.149-19.55-17.956-23.277 14.215-9.16 23.756-13.536 28.622-13.127 5.346.448 9.57 3.812 12.477 9.505 1.074 5.308-1.61 10.885-8.052 16.732-2.366 2.147-7.396 5.536-15.09 10.167z"
              />
              <path
                fill="#92AEE7"
                fillRule="nonzero"
                d="M516.189 229.375c20.042 6.674 42.814 8.282 68.316 4.824-30.905.987-53.306-3.749-67.203-14.206l-1.113 9.382z"
              />
              <path
                fill="#344976"
                fillRule="nonzero"
                d="M162.845 169.659c-.428 3.138-.643 6.803-.643 10.995-.912-.856-2.038-1.56-3.38-2.112.108-2.946.46-6.196 1.06-9.752l2.963.869z"
              />
              <path
                fill="#4E679C"
                fillRule="nonzero"
                d="M134.628 195.784c18.172 5.273 29.827 5.273 34.966 0l-40.498-9.838-4.377 5.338-.868 5.742c2.089.162 3.837.162 5.245 0s3.252-.576 5.532-1.242z"
              />
              <path
                fill="#92AEE7"
                d="M189.375 155.337s-3.445 16.378-10.347 33.192c-9.048 22.05-49.66.824-49.66.824s-7.163 4.488-10.261.964c-.586-.666-2.177-3.806-3.717-4.728-1.54-.923-1.553-3.102-1.54-4.458.187-.24 1.126-2.903 7.886-2.784 2.183.038 6.569 1.906 9.248 2.108 11.835.893 25.127 1.276 30.317 1.276 1.968 0 .026-5.027 1.436-11.754 3.092-14.757 6.532-23.705 6.532-23.705l20.106 9.065z"
              />
              <path
                fill="#4E679C"
                fillRule="nonzero"
                d="M188.431 172.38c-12.161.078-22.578-1.393-31.252-4.413 1.723-12.97 4.745-21.897 9.064-26.782 4.016-4.542 7.172-8.372 14.096-6.652 6.239 2.25 9.99 8.164 11.25 17.74.525 3.986-.527 10.689-3.158 20.107zM526.505 167.838l3.657 9.441c-3.394 15.336-5.314 31.039-5.76 47.11-2.614-1.33-4.98-2.796-7.1-4.396 1.804-14.772 3.378-25.963 4.72-33.572.703-3.98 2.197-10.175 4.483-18.583z"
              />
            </g>
          </svg>
        </div>

        <h1 className="h2 u-margin-bottom">
          <div className="u-color-secondary">What is</div> Atolye15 Checklist
        </h1>

        <p>
          We love to automate anything if it can be automated. For the things we can’t automate
          (yet) we have a protocol. These protocols standardise our workflow so that everyone in
          Atolye15 works on a standard.
        </p>

        <p>
          We have been designing, developing & managing projects that vary in size for over 10 years
          now. Working on a different scale projects brought us some priceless experiences that led
          us to a greater success on the following projects. We updated our workflow, the way we
          work, even the hours we work. We try to adapt to the ongoing growth on technology and we
          are always on track with keeping our protocols updated.
        </p>

        <p>
          This was an internal tool that we still use on every department but we’ve decided to share
          this with the community so that we can help each other out, please feel free to suggest a
          checklist or an improvement on a checklist (on its own page). We will consider every
          suggestion and implement the ones that we think fits our needs and the way we work.
        </p>

        <ButtonAsAnchor
          href="https://www.atolye15.com"
          className="u-margin-top-xlarge u-width-100%@sm-down"
          target="_blank"
          rel="noopener noreferrer"
        >
          atolye15.com
        </ButtonAsAnchor>
      </section>
    </div>
    <hr className="u-margin-ends-0 u-color-primary-900" />
    <section className="o-suggested-checklists">
      <h2 className="u-margin-bottom">Check out these checklists before you go!</h2>
      <div className="row">
        <div className="col col--md-9 col--lg-6">
          <Blockquote>
            We have developed a protocol for anyone to suggest a checklist. We told you we have a
            protocol for almost everything!{' '}
            <span role="img" aria-labelledby="rocket">
              🚀
            </span>
          </Blockquote>
        </div>
      </div>
      <Checklists items={data.suggestions.nodes} className="u-margin-top-medium" />
    </section>
  </Layout>
);

export const pageQuery = graphql`
  query About {
    suggestions: allMarkdownRemark(
      filter: { fields: { slug: { in: ["checklist-checklist", "improvement-checklist"] } } }
    ) {
      nodes {
        ...Checklist
      }
    }
  }
`;

export default About;
